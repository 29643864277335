body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: "20px";
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Ghotam";
  src: url("./assets/fonts/Ghotam/GothamBook.ttf") format("truetype");
  src: url("./assets/fonts/Ghotam//GothamBook.otf") format("OpenType");
}

@font-face {
  font-family: "Segeo UI";
  src: url("./assets/fonts/Segoe-UI/Segoe-UI.ttf") format("truetype");
  src: url("./assets/fonts/Segoe-UI/Segoe-UI.otf") format("OpenType");
  src: url("./assets/fonts/Segoe-UI/Segoe-UI.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/Open-Sans/OpenSans.ttf") format("truetype");
}
